import "./screen14.scss";

import { MemberHolder } from "../../components/member-holder/member-holder";
import { MemberWallet } from "../../components/member-wallet/member-wallet";
import { MemberStore } from "../../components/member-store/member-store";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { OffersWithCategories } from "../../components/offers-with-categories/offers-with-categories";
import { connect } from "react-redux";
import {setAppLoading, setFirstLoad} from "../../../redux/global/global.actions";
import { Steps } from "../steps/steps";
import { setAccountMode } from "../../../redux/holder-cards/holder-cards.actions";
import React, { useEffect, useRef, useState } from "react";
import { useIonViewDidEnter } from "@ionic/react";
import { setSelectedTab } from "../../../redux/tabs/tabs.actions";
import {StepsImagesCollapse} from "../steps/steps-images-collapse/steps-images-collapse";
import {StepsOnlyCard} from "../steps/steps-only-card/steps-only-card";
import {StepsNoCard} from "../steps/steps-no-card/steps-no-card";
import {StepsNoStoreAndCard} from "../steps/steps-no-store-and-card/steps-no-store-and-card";
import {
  OffersWithCategoriesLoadMore
} from "../../components/offers-with-categories/offers-with-categories-with-loadmore";
import {fetchOffersForHomePage} from "../../../redux/offer-list/offer-list.actions";
import {LineLightLink} from "../steps/Line-Light-Link";
import {LineDarkLink} from "../steps/Line-Dark-Link";
import HomePopUp from "./home-popup";

import MembersService from "../../services/members.service";
import {AxiosResponse} from "axios";
import Carousel from "../../components/carousel/carousel";
import membersService from "../../services/members.service";

const mapStateToProps = (state: any) => {
  return {
    selectedStore: state.stores.selectedStore,
    accountScreenMode: state.cards.accountScreenMode,
    cards: state.cards.holders,
    favoriteStores: state.stores.favoriteStores,
    cardsLoading: state.cards.loading,
    isStoresLoading: state.stores.loading,
    offersLoading: state.offerList.offersLoading,
    isAppLoading: state.global.isAppLoading,
    spotlight: state.global.spotlight,
    spotlights: state.global.spotlights,
    first_load: state.global.first_load,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchOffers: (catId: string) => dispatch(fetchOffersForHomePage(catId)),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    setAccountMode: (data: any) => dispatch(setAccountMode(data)),
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
    setFirstLoad: (data: any) => dispatch(setFirstLoad(data)),
  };
};

interface Props {
  selectedStore: any;
  spotlight: any;
  spotlights: any;
  cards: any[];
  fetchOffers: Function;
  isStoresLoading: boolean;
  accountScreenMode: boolean
  cardsLoading: boolean;
  favoriteStores: any[];
  isAppLoading: boolean;
  first_load: boolean;
  offersLoading: boolean;
  setAppLoading: Function;
  setSelectedTab: Function;
  setAccountMode: Function;
  setFirstLoad: Function;
}

export const Screen14Component = (props: Props) => {

  const [showBanner, setShowBanner] = useState(false);
  const [video, setVideo] = useState(null);
  const isHomePopupShown = localStorage.getItem("isHomePopupShown");
  const [showHomePopup, setShowHomePopup] = useState(false);

  const getMemberCreatedAt = async (): Promise<AxiosResponse<any>> => {
    const res = await MembersService.getMember();
    const created_at = new Date(res.data.created_at);
    return res;
  };

  const manageShowHomePopupBasedOnCreationDate = async () => {
    const member = await getMemberCreatedAt();
    // Only show popup, if it was not shown to the user previously
    if(!isHomePopupShown || isHomePopupShown != member.data.id) {
      console.log("POPUP NOT SHOWN BEFORE");


      const now = new Date();
      const timeDiff = now.getTime() - new Date(member.data.created_at).getTime();

      // user created in the last 60 minutes

      if(timeDiff < (60 * 60 * 1000)) {
        console.log("MEMBER CREATED IN LAST ONE HOUR, SHOWING POPUP");
        setShowHomePopup(true);

        setTimeout(() => {
          setShowHomePopup(false);
          console.log("HIDING POPUP");
          localStorage.setItem("isHomePopupShown", member.data.id + '');

        // show popup for 3 seconds (3000)
        }, 3000);
      } else {
        console.log("MEMBER CREATED MORE THAN 1 HOUR AGO");
      }
    } else {
      console.log("POPUP ALREADY SHOWN");
    }
  }

  useEffect(() => {

    manageShowHomePopupBasedOnCreationDate();
  }, []);

  useIonViewDidEnter(() => {
    // console.log('ionViewDidEnter event fired: ');
    props.setSelectedTab('home');
    props.setAccountMode(false);

  });
  const selectedStore = props.selectedStore ? props.selectedStore : { retailer_id: "-1" };
  let accountScreenMode = false;
  let retailer_id: string = null;
  let holder = accountScreenMode ? props.cards.filter(
      (c) => c.retailer_id === parseInt(retailer_id)
  )[0] : props.cards.filter(
      (c) => c.retailer_id === parseInt(selectedStore.retailer_id)
  )[0]

  let app_retailer_id = localStorage.getItem("app_retailer_id");

  retailer_id = accountScreenMode ? retailer_id : holder ? holder.retailer_id : props.selectedStore ? props.selectedStore.retailer_id : app_retailer_id ? app_retailer_id : null;

  // let retailers = [waonId, izumiId, iyId, ybId]
  let id_filter: any = [];

  if (app_retailer_id) {
    id_filter = app_retailer_id.split(',');
    let registeredCards = []
    if (id_filter.length > 0) {
      registeredCards = props.cards.filter((c1) => id_filter.includes(c1.retailer_id.toString()))
    }
    if (registeredCards.length > 0) {
      holder = registeredCards[0];
    }
  } else {
    if (!retailer_id && !holder && props.cards.length > 0) {
      holder = props.cards[0];
    }
  }

  retailer_id = accountScreenMode ? retailer_id : holder ? holder.retailer_id : props.selectedStore ? props.selectedStore.retailer_id : app_retailer_id ? app_retailer_id : null;
  // console.log(retailer_id)
  const stepSection = useRef(null);
  const scrollDown = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    console.log('scrolled' + window.screenX)
  };

  let resetScroll = (trigger:boolean)=>{
    if(props.first_load){
      props.setFirstLoad(trigger);
    }
  }
  useEffect(() => {
    let date = new Date();
    if (date < new Date("11/01/2022 02:00:00")) { setShowBanner(true) }
    props.setAccountMode(false);
  }, [])
  useEffect(() => {
    if(props.spotlights && props.spotlights.length>0){
      let first_spotlight = props.spotlights[0];
      // MembersService.getVideo(first_spotlight.destination.video_location.video_id).then((res)=>{
      //     console.log(res.data)
          setVideo(first_spotlight.detail_url);
          // }

      // );

    }
  }, [props.spotlights]);
  console.log(holder)
  return (
    <ScrollpageWithHeader
        onScroll={resetScroll}
        shouldTrackScroll={props.first_load}
        isAppLoading={props.isAppLoading}
      title="クーポン"
      showBackButton={true}

      id="screen-14"
      showSelectStoreIcon={
        (props.selectedStore === null && props.isStoresLoading === false) ||
        (props.cards.length === 0 && props.cardsLoading === false)
      }
    >

      {props.isAppLoading && (
        <div className="flex justify-center pt-24">
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      )}
      {!props.isAppLoading && showBanner &&
        <div className="px-2 py-2 text-sm border border-gray-900 m-1.5 font-black text-center"
          style={{ "borderRadius": "1rem" }}>
          <div className="text-sm">
            システムメンテナンスのお知らせ
            <br />
            期間：11/1(火) 00:00〜02:00<br />
          </div>
        </div>
      }
      {!props.isAppLoading && (
        <div>
          <div className="flex-1">

            <MemberStore />
            <div style={{ background: "#F5F5F5", height: "0.0625rem", marginTop: "1.375rem" }}>
              &nbsp;
            </div>
            <MemberHolder stepSection={stepSection} />
            {props.cards.length != 0 && props.selectedStore &&
                <>
                  <MemberWallet />
                  <div className="h-2 mt-4 bg-gray-100">&nbsp;</div>
                </>
            }
            {/*<div className="px-4 mt-2 text-sm underline" onClick={() => scrollDown(stepSection)}>クーポンプラス使い方はこちら</div>*/}
            {video &&
                <div className="relative overflow-hidden md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
                  {/*<img*/}
                  {/*    className="h-full w-full object-cover"*/}
                  {/*    src={video.main_picture_url}*/}
                  {/*    alt=""*/}
                  {/*/>*/}
                  <video playsInline={true} controls autoPlay muted className="w-full object-contain" onEnded={() => {
                    console.log('Video ended!');
                  }}>
                    <source src={video} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>

                </div>

              // <Carousel spotlights={props.spotlights}/>
            }

            {/* {props.selectedStore &&
              props.selectedStore.retailer &&
              props.selectedStore.retailer.name && (
                <div className="text-gray-700 text-sm pl-4 py-3">
                  クーポン | {props.selectedStore.retailer.name}
                </div>
              )} */}
            {holder && selectedStore.retailer_id!=-1 &&
              <OffersWithCategories />
            }

          </div>
          {holder && props.selectedStore &&
              <div className="section steps" ref={stepSection}>
                {/*{holder && !props.accountScreenMode && <StepsImagesCollapse />}*/}
                {/*{holder && !props.accountScreenMode && <StepsOnlyCard />}*/}
                {/*{!holder &&  (selectedStore.retailer_id!=-1) && !props.accountScreenMode && <StepsNoCard />}*/}
                {/*{!holder && (selectedStore.retailer_id==-1) && !props.accountScreenMode && <StepsNoStoreAndCard />}*/}
                {(!holder || (selectedStore.retailer_id == -1)) && !props.accountScreenMode && <>
                  {/*<div className="flex justify-center my-4 text-xl" style={{*/}
                  {/*  color: "#FF592A",*/}
                  {/*  fontWeight: 700*/}
                  {/*}}>*/}
                  {/*  クーポン一例*/}
                  {/*</div>*/}
                  {/*<div className="flex text-xs justify-center my-1 ">*/}
                  {/*  ・お店とポイントカード登録後に利用可能です。*/}
                  {/*</div>*/}
                  {/*<div className="flex text-xs justify-center my-1 ">*/}
                  {/*  ・利用されるお店で対象外の場合がございます。*/}
                  {/*</div>*/}
                  <div className="h-2 bg-gray-50">&nbsp;</div>
                  {/*<OffersWithCategoriesLoadMore*/}
                  {/*    homeScreenMode={true}*/}
                  {/*    fetchOffers={props.fetchOffers}*/}
                  {/*/>*/}


                </>
                }

                {holder && props.selectedStore && !props.accountScreenMode && <Steps/>}
              </div>
          }
          {(!holder || !props.selectedStore) && !props.accountScreenMode &&
              <div className="w-full">
                <LineLightLink></LineLightLink>
                <div className="h-2 bg-gray-50">&nbsp;</div>
                <LineDarkLink></LineDarkLink></div>
          }
          {showHomePopup && <div className="fixed bottom-0 left-0 bg-white border-t" style={{
            boxShadow: "0px -1px 4px 1px rgb(0 0 0 / 12%)",
          }}>
            <HomePopUp/>
          </div>}

        </div>
      )}
    </ScrollpageWithHeader>
  );
};

export const Screen14 = connect(
    mapStateToProps,
    mapDispatchToProps
)(Screen14Component);
